import { MapLayer } from "react-leaflet";
import L from "leaflet";
import { makeStyles } from '@material-ui/styles';
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "./styles.css";
import { withLeaflet } from "react-leaflet";
import "lrm-graphhopper";

{/* 

Mapbox
router: L.Routing.mapbox('pk.eyJ1Ijoic3RhaXJ3YXkyaGVhdmVuIiwiYSI6ImNrOTcydmZtdjBrZ3EzZW1wam9hcXByMWoifQ.P3jcYMCrTEGvZFncZ9JHIw',{ profile : 'mapbox/walking' }),

Graphhopper
router: new L.Routing.GraphHopper('9b3e6908-ff51-4946-9af6-313bb078fa09', { urlParameters: { vehicle: 'hike' } }),

Read https://github.com/perliedman/leaflet-routing-machine/issues/400

Read https://github.com/perliedman/leaflet-routing-machine/issues/20
    hidden with styles.css import
*/}



class Routing extends MapLayer {
  createLeafletElement() {
    const { map } = this.props;
    var leafletElement = L.Routing.control({
      router: new L.Routing.GraphHopper('9b3e6908-ff51-4946-9af6-313bb078fa09',{ urlParameters: { vehicle: 'foot' } }),
      waypoints: [L.latLng(53.041884,-6.341858), L.latLng(53.031149,-6.348381), L.latLng(53.0543,-6.38254)],
      addWaypoints: false,
      show: false
    }).addTo(map.leafletElement);
    
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);