import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardActionArea, Grid, Typography, Slider } from '@material-ui/core';
import MoneyIcon from  '@material-ui/icons/DirectionsWalk';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textAlign: 'center'
  },
                               
  icon: {
    color: theme.palette.info.main,
    transform: "rotate(-15deg)" ,
    height: 24,
    width: 24                   
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  panel: {
    paddingTop:0,
    paddingBottom:'0 !important'
  },
  slider: {
    paddingTop:15,
    paddingBottom:15
  }


}));


const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 600,
    label: '600 m/h',
  },
  
  {
    value: 1200,
    label: '1200',
  }
];


const SpeedClimbCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.panel}>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Speed Climbing
            </Typography>
            
          </Grid>
          <Grid item>
            <MoneyIcon className={classes.icon} />
          </Grid>
        </Grid> 
              <CardActionArea>
        <Slider className={classes.slider}
          defaultValue={600}
          aria-labelledby="discrete-slider-always"
          step={1}
          marks={marks}
          valueLabelDisplay="auto"
          component="div"
          max={1200}
        />
      </CardActionArea>
      </CardContent>
    </Card>
  );
};

SpeedClimbCard.propTypes = {
  className: PropTypes.string
};

export default SpeedClimbCard;
