import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';

const options =  {
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      maintainAspectRatio: false,

      scales: {
            xAxes: [{
                ticks: {
                    display: false //this will remove only the label
                }
            }],
            yAxes: [{
              ticks: {
                  beginAtZero: true
            }
        }]
        }
}

const data = {
  labels: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40],
  datasets: [
    {
      label: "Elevation",
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

export default class LineDemo extends Component {
  render() {
    return (
       <div> 
        <Line ref="chart" data={data} options={options}/>
      </div>
    );
  }

  componentDidMount() {
    const { datasets } = this.refs.chart.chartInstance.data
    console.log(datasets[0].data);
  }
}