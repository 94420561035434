import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Map as MapView,
  Card as CardView,
  Chart as ChartView,
  Weather as WeatherView,
  Share as ShareView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/map"
      />
      <RouteWithLayout
        component={MapView}
        exact
        layout={MainLayout}
        path="/map"
      />
      <RouteWithLayout
        component={CardView}
        exact
        layout={MainLayout}
        path="/card"
      />
      <RouteWithLayout
        component={ChartView}
        exact
        layout={MainLayout}
        path="/chart"
      />
      <RouteWithLayout
        component={WeatherView}
        exact
        layout={MainLayout}
        path="/weather"
      />
      <RouteWithLayout
        component={ShareView}
        exact
        layout={MainLayout}
        path="/share"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
