import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  RouteCard, GainCard, LengthCard, DurationCard, ArriveCard, SunriseCard, SunsetCard, SpeedFlatCard, SpeedClimbCard, StartTimeCard
} from './components'



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Card = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
       <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <LengthCard />
        </Grid>
        <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <GainCard />
        </Grid>
        <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <DurationCard />
        </Grid>
        <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <ArriveCard />
        </Grid>
        <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <SunriseCard />
        </Grid>
        <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={3}
        >
          <SunsetCard />
        </Grid>
        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <RouteCard />
        </Grid>
 
      </Grid>
    </div>
  );                              
};

export default Card;
