import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    coord: '53.053, -6.387',
    alti: '778m',
    localGrid: 'O 08201 01410',
    magDecl: -3,
    metersFlat: '1,169m',
    metersClimb: '2m',
    timeFlat: '00:14:01',
    timeClimb: '00:00:12',
    timeTotal:'00:14:13',
    bearing: 101,
    magBearing: 98,
    timeArrive: '12:44:00',
    timeRest: '00:00:00'
  },
    {
    id: uuid(),
    coord: '53.051, -6.37',
    alti: '543m',
    localGrid: 'O 09357 01218',
    magDecl: -3,
    metersFlat: '1,781m',
    metersClimb: '60m',
    timeFlat: '00:21:22',
    timeClimb: '00:06:00',
    timeTotal:'00:27:22',
    bearing: 55,
    magBearing: 52,
    timeArrive: '01:11:00',
    timeRest: '00:00:00'
  }
];
