import React, {useState}  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardActionArea, Grid, Typography } from '@material-ui/core';
import MoneyIcon from  '@material-ui/icons/Schedule';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { KeyboardDateTimePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";

function BasicDateTimePicker() {
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
        variant="inline"
        ampm={false}
        value={selectedDate}
        onChange={handleDateChange}
        onError={console.log}
        disablePast
        format="yyyy/MM/dd HH:mm"
        />
      </MuiPickersUtilsProvider >

  );
}


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textAlign: 'center'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    
  },
                               
  icon: {
    color: theme.palette.info.main,
    height: 24,
    width: 24                   
  },
  slider: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
 panel: {
    paddingTop:0,
    paddingBottom:'0 !important',
  },
  slider: {
    paddingTop:0,
    paddingBottom:0
  }
}));

const StartTimeCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.panel}>
        <Grid
          container
          justify="space-between"
        >
          <Grid item >
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Start Time
            </Typography>
            
          </Grid>
          <Grid item>
            <MoneyIcon className={classes.icon} /> 
            </Grid>
        </Grid> 
        <Grid item>
        <CardActionArea className={classes.slider}>
        <BasicDateTimePicker />
        </CardActionArea>
        </Grid>
      </CardContent>
    </Card>
  );
};

StartTimeCard.propTypes = {
  className: PropTypes.string
};

export default StartTimeCard;
