import React, { Component }    from 'react';
import { Map, TileLayer,Marker,Popup, Polyline, type Viewport } from 'react-leaflet';
import Routing from "./RoutingMachine";

const mapboxToken = 'pk.eyJ1Ijoic3RhaXJ3YXkyaGVhdmVuIiwiYSI6ImNrOTcydmZtdjBrZ3EzZW1wam9hcXByMWoifQ.P3jcYMCrTEGvZFncZ9JHIw'

const DEFAULT_VIEWPORT = {
  center: [53.031149,-6.348381],
  zoom: 16,
}

export default class LeafletMap extends Component < {},  { viewport: Viewport }, > {
  state = {
    viewport: DEFAULT_VIEWPORT,
    markers: []
   }

   saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };
  onClickReset = () => {
    this.setState({ viewport: DEFAULT_VIEWPORT })
  }

  addMarker = (e) => {
    const {markers} = this.state
    markers.push(e.latlng)
    this.setState({markers})
  }

  onViewportChanged = (viewport: Viewport) => {
    this.setState({ viewport })
  }
                                                      
  updateMarker = event => {
    const latLng = event.target.getLatLng(); //get updated marker LatLng
    const markerIndex = event.target.options.marker_index; //get marker index
    //update 
    this.setState(prevState => {
      const markerData = [...prevState.markers];
      markerData[markerIndex] = latLng;
      return { markers: markerData };
    });
 };

 
  render() {
 
  return (   
        <Map
          onClick={this.addMarker}
          onViewportChanged={this.onViewportChanged}
          viewport={this.state.viewport}
          style={{ width: '100%', height: '100%'}}
          ref={this.saveMap}
          
          >
{/* 
Thunderforest
attribution='Maps © <a href="http://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>' 
url="https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=2459201d91ac4cbda9bdd6357c5274c5"

mapbox
attribution='Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>'
url="https://api.mapbox.com/styles/v1/stairway2heaven/ck9lj9kgk24vx1ipd1aqkwser/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RhaXJ3YXkyaGVhdmVuIiwiYSI6ImNrOTcydmZtdjBrZ3EzZW1wam9hcXByMWoifQ.P3jcYMCrTEGvZFncZ9JHIw"
    
Graphhopper
attribution='Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.graphhopper.com/&quot;>Graphhopper</a>'
url="https://api.mapbox.com/styles/v1/stairway2heaven/ck9lj9kgk24vx1ipd1aqkwser/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RhaXJ3YXkyaGVhdmVuIiwiYSI6ImNrOTcydmZtdjBrZ3EzZW1wam9hcXByMWoifQ.P3jcYMCrTEGvZFncZ9JHIw"
  
open topo map
attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"    
*/}
          <TileLayer
            attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"            
            />

          {this.state.isMapInit && <Routing map={this.map} />}
          
        
          
          // Draw Polyline
          {/*}
          {this.state.data.map(({id, from_lat, from_long, to_lat, to_long}) => {
              return <Polyline key={id} positions={[
                [from_lat, from_long], [to_lat, to_long],
              ]} color={'red'} />
            })}
          */}

          
          
          // Draw Static Marker
           {/*  <Marker
            draggable={this.state.draggable}
            onDragend={this.updatePosition}
            position={markerPosition}
            ref={this.refmarker}>
            <Popup minWidth={90}>
              <span onClick={this.toggleDraggable}>
                {this.state.draggable ? 'DRAG MARKER' : 'MARKER FIXED'}
              </span>
            </Popup>
          </Marker>
          */}
          // Draw Dynamic Marker
       
          {this.state.markers.map((position, idx) => 
          <>
             <Marker key={`marker-${idx}`} marker_index={idx} position={position} draggable='DRAG MARKER' onDragend={this.updateMarker}>
              {/* <Popup>
                <span onClick={this.toggleDraggable}>
                    {idx} {this.state.draggable ? 'DRAG MARKER' : 'MARKER FIXED'}
                  </span> 
              </Popup> */}
              </Marker>
            <Polyline  key={`polyline-${idx}`} positions={this.state.markers} color={'black'} /> 
           
            </>
        )} 


           

        </Map>
      )
    }

}
