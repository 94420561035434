import React, {Component} from 'react';
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


export default class Meteogram extends Component {
  instance;

  options = {
    chart: {
      type: "area"
    },
    title: {
      text: "Bar Chart"
    },
    series: [
      {
        data: [1, 3, 4, 10, 9]
      }
    ]
  };

  componentDidMount() {
    //this.instance = Highcharts.chart("dummy-id", this.options);
  }

  render() {
    return (
      <div>
        <img src="https://www.yr.no/en/content/53.265,-6.34/meteogram.svg" />
      </div>
    );
  }
}
