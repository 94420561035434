import React , {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, Grid, Typography, Slider, FormRow } from '@material-ui/core';
import MoonIcon from  '@material-ui/icons/Brightness3Outlined';
import RunIcon from  '@material-ui/icons/DirectionsRun';
import WalkIcon from  '@material-ui/icons/DirectionsWalk';
import ScheduleIcon from  '@material-ui/icons/Schedule';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import { KeyboardDateTimePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";

function BasicDateTimePicker() {
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
        variant="inline"
        ampm={false}
        value={selectedDate}
        onChange={handleDateChange}
        onError={console.log}
        disablePast
        format="yyyy/MM/dd HH:mm"
        />
      </MuiPickersUtilsProvider >

  );
}


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textAlign: 'center'
  },

  griditem: {
    borderColor: theme.palette.info.main,
    alignItems: 'center',
    
  },
       
  icon: {
    color: theme.palette.info.main,
    height: 24,
    width: 24                      
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  slider: {

    color: theme.palette.info.light,
    width: 200
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const marksFlat = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5 km/h',
  },  
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  }
];

const marksClimb = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 600,
    label: '600 m/h',
  },
  
  {
    value: 1200,
    label: '1200',
  }
];


const Controls = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Grid container justify="space-around" >

    <Grid item lg={4} sm={4} xl={4} xs={4} >
      <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="body2" >
          Speed on Flat
        </Typography>
        <Grid container justify="space-around">

          <Grid item >
            <Slider
              className={classes.slider}
              defaultValue={5}
              aria-labelledby="discrete-slider-always"
              step={1}
              valueLabelDisplay="auto"
              max={15}
            />
        </Grid>

      </Grid>
    </Grid>

    <Grid item lg={4} sm={4} xl={4} xs={4} 
    className={classes.griditem} >
    
      <Typography
        className={classes.title}
        color="textSecondary"
        gutterBottom
        variant="body2" >
        Speed Climbing
      </Typography>
      <Grid container justify="space-around">
        
        <Grid item >
          <Slider
          className={classes.slider}
          defaultValue={600}
          aria-labelledby="discrete-slider-always"
          step={10}
          valueLabelDisplay="auto"
          max={1200}
          />
        </Grid>

      </Grid>
     
    </Grid>

    <Grid item lg={4} sm={4} xl={4} xs={4} >
      <Typography
        className={classes.title}
        color="textSecondary"
        gutterBottom
        variant="body2">
        Start Time
      </Typography>
       <Grid container justify="space-around"> 
        <Grid item >
          <BasicDateTimePicker />
        </Grid>
      </Grid>
    </Grid>    
    
    </Grid>
  
  );
};

Controls.propTypes = {
  className: PropTypes.string
};

export default Controls;
