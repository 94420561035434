import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LeafletMap from './LeafletMap';
import ArrowRightIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(() => ({
  root: {},
  mapContainer: {
    height: 600,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const MapCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
   
   return (
  
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Route Designer"
      />
      <Divider />
      <CardContent>
        <div id="mapContainer" className={classes.mapContainer}>
        <LeafletMap />
        </div>
      </CardContent>
      <Divider />
    <CardActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<ArrowRightIcon />}
        >
          Export GPX 
        </Button>
      </CardActions>
    </Card>
  );
};

MapCard.propTypes = {
  className: PropTypes.string
};



export default MapCard;
