import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

import LineDemo from './LineChart';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
                               
  icon: {
    color: theme.palette.warning.main,
    height: 24,
    width: 24                    
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const ElevationChartCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader

        title="Elevation Chart"
      />
      <Divider />
      <CardContent>
      <LineDemo/>
      </CardContent>
      <Divider />

    </Card>
  );
};

ElevationChartCard.propTypes = {
  className: PropTypes.string
};

export default ElevationChartCard;
