import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid
} from '@material-ui/core';



import {
  Controls, SpeedFlatCard,SpeedClimbCard,StartTimeCard
} from '..'

import ArrowRightIcon from '@material-ui/icons/GetApp';

import mockData from './data';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.main,
    textAlign: 'center',
    borderRight: '1px solid #ffffff ',
  },
   rowborder: {
      borderRight: '1px solid #eeeeee ',
      textAlign: 'center'
  }
}));



const RouteCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [orders] = useState(mockData);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Waypoints"
      />
      <Divider />
      <br />
      <Grid container justify="space-evenly" spacing={2}>
      <Grid item lg={3} sm={4} xl={4} xs={4} >
      <SpeedFlatCard />
      </Grid>
      <Grid item lg={3} sm={4} xl={4} xs={4} >
      <SpeedClimbCard />
      </Grid>
      <Grid item lg={3} sm={4} xl={4} xs={4} >
      <StartTimeCard />
      </Grid>
      </Grid>
      <br />
      
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead className={classes.head}>
                <TableRow >
                  <TableCell className={classes.rowborder}>Coord</TableCell>
                  <TableCell className={classes.rowborder}>Alti</TableCell>
                  <TableCell className={classes.rowborder}>Grid</TableCell>
                  <TableCell className={classes.rowborder}>Mag Decl</TableCell>
                  <TableCell className={classes.rowborder}>Mtrs Flat</TableCell>
                  <TableCell className={classes.rowborder}>Mtrs Climb</TableCell>
                  <TableCell className={classes.rowborder}>Time Flat</TableCell>
                  <TableCell className={classes.rowborder}>Time Climb</TableCell>             
                  <TableCell className={classes.rowborder}>Time Total</TableCell>
                  <TableCell className={classes.rowborder}>Bearing</TableCell>
                  <TableCell className={classes.rowborder}>Mag Bearing</TableCell>
                  <TableCell className={classes.rowborder}>Time Arrive</TableCell>
                  <TableCell className={classes.rowborder}>Time Rest</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(order => (
                  <TableRow
                    hover
                    key={order.id}
                  
                  >
                    <TableCell className={classes.rowborder}>{order.coord}</TableCell>
                    <TableCell className={classes.rowborder}>{order.alti}</TableCell>
                    <TableCell className={classes.rowborder}>{order.localGrid}</TableCell>
                    <TableCell className={classes.rowborder}>{order.magDecl}</TableCell>
                    <TableCell className={classes.rowborder}>{order.metersFlat}</TableCell>
                    <TableCell className={classes.rowborder}>{order.metersClimb}</TableCell>
                    <TableCell className={classes.rowborder}>{order.timeFlat}</TableCell>
                    <TableCell className={classes.rowborder}>{order.timeClimb}</TableCell>
                    <TableCell className={classes.rowborder}>{order.timeTotal}</TableCell>
                    <TableCell className={classes.rowborder}>{order.bearing}</TableCell>
                    <TableCell className={classes.rowborder}>{order.magBearing}</TableCell>
                    <TableCell className={classes.rowborder}>{order.timeArrive}</TableCell>
                    <TableCell className={classes.rowborder}>{order.timeRest}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<ArrowRightIcon />}
        >
          Export CSV 
        </Button>
      </CardActions>
    </Card>
  );
};

RouteCard.propTypes = {
  className: PropTypes.string
};

export default RouteCard;
